<template>
    <el-container class="page-container">
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->

        <!--</el-header>-->

        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-position='left' label-width="80px" :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-input placeholder="请输入内容" v-model="query.searchKeyword" @keyup.enter.native="doSearch">
                            <el-select v-model="query.searchName" slot="prepend" placeholder="查询类型" style="width: 120px;">
                                <el-option label="位置编码" value="code"></el-option>
                                <el-option label="名称" value="name"></el-option>
                                <el-option label="描述" value="descriptions"></el-option>
                                <el-option label="宽度" value="width"></el-option>
                                <el-option label="高度" value="height"></el-option>
                                <el-option label="操作时间" value="updateTime"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

            <el-row class="row-btn-group">
                <el-button type="primary" @click="handleAdd('新增')">
                    <i class="cw-icon cw-icon-tianjia"></i>新增
                </el-button>
                <el-button type="danger" @click="doDelMore" :disabled="selectData.length==0">
                    <i class="cw-icon cw-icon-tianjia"></i>删除选中
                </el-button>
            </el-row>
            <el-table
                    :data="tableData"
                    ref="multipleTable"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        prop="code"
                        label="位置编码"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="名称"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>

                <el-table-column
                        prop="descriptions"
                        label="描述"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="width"
                        label="宽度"
                        :show-overflow-tooltip="true"
                >

                </el-table-column>
                <el-table-column
                        prop="height"
                        label="高度"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="updateTime"
                        label="操作时间"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="180"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="handleEdit(scope.row)" plain>修改</el-button>
                        <el-button size="mini" type="success" @click="doDelete(scope.row)" plain>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
        <form-dialog @close="handleClose('editForm')" :title="editTitle" :dialogVisible="visibleFormDialog">
            <template slot="content">
                <el-form :model="formData" ref="editForm" :rules="formRules" label-position="right" label-width="80px">
                    <el-form-item prop="code" label="位置编码">
                        <el-input maxlength="100" placeholder="位置编码"
                                  v-model="formData.code"
                                  auto-complete="off" tabindex="1" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="name" label="名称">
                        <el-input maxlength="100" placeholder="名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="2" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="descriptions" label="描述">
                        <el-input maxlength="100" placeholder="描述"
                                  v-model="formData.descriptions"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="width" label="宽度">
                        <el-input maxlength="100" placeholder="宽度"
                                  v-model="formData.width"
                                  auto-complete="off" tabindex="4" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="height" label="高度">
                        <el-input maxlength="100" placeholder="高度"
                                  v-model="formData.height"
                                  auto-complete="off" tabindex="5" type="text"></el-input>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="doEdit">确 定</el-button>
                        <el-button @click="closeModul">取 消</el-button>
                    </div>
                </el-form>

            </template>
        </form-dialog>
    </el-container>

</template>

<script>
  import {mapGetters} from 'vuex'
  import formDialog from '../../components/formDialog'
  import {positionData,positionAdd,positionEdit,positionDeleteOne,positionDeleteMore} from '../../api/ads'
  import moment from 'moment'
  export default {
    name: "companyManagement",
    components: {formDialog},
    data() {
      return {
        tableData: [],
        pageInfo: {
          totalCount: 0,
          pageNumber: 1,
          pageSize: 10,
          pageOrderName:'opAt',
          pageOrderBy:'descending'
        },
        query: {
          searchName: '',
          searchKeyword: ''
        },
        visibleFormDialog:false,
        formData: {
          code:'',
          name:'',
          descriptions:'',
          width:'',
          height:''
        },
        editTitle:'',
        formRules: {
          code: [
            {required: true, message: '位置编码', trigger: 'blur'}
          ],
          name: [
            {required: true, message: '名称', trigger: 'blur'}
          ],
          descriptions: [
            {required: true, message: '描述', trigger: 'blur'}
          ],
          width: [
            {required: true, message: '宽度', trigger: 'blur'}
          ],
          height: [
            {required: true, message: '高度', trigger: 'blur'}
          ],
          updateTime: [
            {required: true, message: '操作时间', trigger: 'blur'}
          ],
        },
        selectData:[]
      }
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),
    },
    methods: {
      handleSearch() {
        this.pageInfo.pageNumber = 1;
        this.positionData()
      },
      async positionData() {
        try {
          let query = Object.assign({}, this.pageInfo, this.query)
          let formData = {}
          for (let i in query) {
            if (query[i] !== '' && i !== 'total' && query[i] !== -1) {
              formData[i] = query[i]
            }
          }
          let resonse = await positionData(formData)
          let {data} = resonse;
          this.tableData = data.list;
          this.pageInfo.totalCount = data.totalCount;
        } catch (e) {
          console.log(e)
        }
      },
      clearSearch() {
        this.query={
          searchName: '',
          searchKeyword: ''
        },
          this.handleSearch()
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.pageInfo.page = 1
        this.positionData()
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNumber = val
        this.positionData()
      },
      handleClose(form) {
        this.$refs[form].resetFields();
        this.formData={};
        this.visibleFormDialog = false;
      },
      handleAdd() {
        this.editTitle = '新增';
        this.visibleFormDialog = true;
      },
      handleEdit(row) {
        console.log(428,row);
        this.editTitle = '修改';
        this.formData=row;
        this.visibleFormDialog = true
      },
      //修改
      async doEdit(){
        let updateTime=moment().format('YYYY-DD-MM HH:mm:ss');
        this.$refs['editForm'].validate(async (valid)=>{
          if(valid){
            try {
              this.formData.id?await positionEdit(this.formData):await positionAdd(Object.assign(this.formData,{updateTime:updateTime}));
              this.$message({
                type: 'success',
                message: '更新成功!'
              });
              this.visibleFormDialog = false;
              this.$refs['editForm'].resetFields();
              this.positionData()
            }catch (e) {

            }
          }
        })
      },
      //删除
      async doDelete(row){
        console.log(308,row);
        this.$confirm('删除后无法恢复，确定删除'+row.name+'吗?', '确定', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            await positionDeleteOne(row.id);
            this.$message({
              type: 'success',
              message: '更新成功!'
            });
            this.positionData()
          }catch (e) {
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
      handleSelectionChange(val){
        console.log(334,val);
        this.selectData=val;
      },
      closeModul(){
        this.visibleFormDialog = false;
        this.formData={};
        this.positionData();
      },
      async doDelMore(){
        let ids = [];
        this.selectData.forEach(function (val) {
          ids.push(val.id);
        });
        this.$confirm('删除后无法恢复，确定删除选中选项吗?', '确定', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            await positionDeleteMore({ids: ids.toString()});
            this.$message({
              type: 'success',
              message: '更新成功!'
            });
            this.positionData()
          }catch (e) {
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
    },
    mounted() {
      this.positionData()
    }
  }
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }

    }
    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }
    .el-input__prefix{
        left: 15px;
        color: #303133;
        i{
            font-style:normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }


</style>
